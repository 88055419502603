<script lang="ts">
  import { type Options, Splide } from "@splidejs/svelte-splide";
  import TestimonialSplideSlide from "./testimonial-splide-slide.svelte";
  import "@splidejs/svelte-splide/css";

  const options: Options = {
    type: "loop",
    focus: "center",
    width: "92rem",
    gap: "2em",
    autoWidth: true,
    autoplay: true,
    interval: 10e3,
  };
</script>

<Splide class="mx-auto" {options}>
  <TestimonialSplideSlide name="Case Hushen">
    I'm on my third wrap from ArcticFX now and I can say with confidence that I will use them on all
    future machines. I've tried other brands before but quality and fit/finish with ArcticFX is
    amazing.&hellip; Thanks for making a great product.
  </TestimonialSplideSlide>
  <TestimonialSplideSlide name="Jillian Harper">
    I would recommend ArcticFX to anyone looking for a wrap. Their customer service is above anyone
    I have dealt with in the past. When I ordered my wrap it was there within that same week, any
    questions or issues were resolved in less than 24 hours.
  </TestimonialSplideSlide>
  <TestimonialSplideSlide name="@backcountry_sledders">
    'Gnargaritaville' is by far my favorite sled. Huge thanks to ArcticFX Graphics on blowing this
    wrap out of the park. They took my idea and made it 100x better!
  </TestimonialSplideSlide>
  <TestimonialSplideSlide name="Jennifer Messick">
    Absolutely stunning! I checked with installation places before deciding which company to get a
    wrap from, nearly everyone said Arctic FX.&hellip; So far held up for one season, no issues at
    all. Would highly recommend.
  </TestimonialSplideSlide>
  <TestimonialSplideSlide name="Nathan Kressly">
    They pay attention and take care of their customers! They noticed a mistake in my order and
    called me to fix it!
  </TestimonialSplideSlide>
  <TestimonialSplideSlide name="Marvin Copenhaver">
    These guys are absolutely incredible, their designs are amazing. I had dropped off my sled here
    for them to install as well, and did an amazing job on that.&hellip; I personally had paid the
    extra for the metal flake in the wrap and it turned out beautifully.
  </TestimonialSplideSlide>
  <TestimonialSplideSlide name="Rob Barfitt">
    Great graphics! Everything went together very well with minimum effort! Highly recommend.
  </TestimonialSplideSlide>
  <TestimonialSplideSlide name="Jesse Lombardo">
    Jordan was personable, creative, professional, and down right awesome. He created some decals
    for my truck the same day and made them of high quality materials. Highly recommend this
    company!
  </TestimonialSplideSlide>
</Splide>
